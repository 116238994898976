import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { getContractConfig } from "utils";
import { CONTRACTS, NETWORK, CLOUD } from "config";

import Contracts from "contracts/hardhat_contracts.json"
import axios from "axios";

export default function useContracts(provider) {
  const [contractYogies, setContractYogies] = useState(null);  
  const [contractYogiesStaking, setContractYogiesStaking] = useState(null);
  const [contracts, setContracts] = useState();

  async function fetchContracts() {
        setContracts(Contracts)    
    }

  useEffect(() => {
    if (provider && contracts) {
      (async function () {
        const contractData = {};
        let isCorrectChainId = provider.chainId === NETWORK.TARGET_CHAIN_ID;

        if (isCorrectChainId) {
          Object.keys(CONTRACTS).forEach((item) => {
            const config = getContractConfig(
              provider.chainId.toString(),
              CONTRACTS[item],
              contracts
            );
            if (config) {
              contractData[CONTRACTS[item]] = new ethers.Contract(
                config.address,
                config.abi,
                provider.instance
              );
            }
          });
        } else {
          alert(
            `Wrong network. Please connect to the ${
              NETWORK.CHAIN_ID_TO_NAME[NETWORK.TARGET_CHAIN_ID]
            } network.`
          );
          return;
        }

        setContractYogies(contractData[CONTRACTS.YOGIES]);
        setContractYogiesStaking(contractData[CONTRACTS.YOGIES_STAKING]);
      })();
    }
  }, [provider, contracts]);

  useEffect(() => {
    fetchContracts();
  }, [])

  return [
    contractYogies,
    contractYogiesStaking
  ];
}
