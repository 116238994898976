import React, { useState, useEffect } from "react";
import Footer from "components/footer";
import ErrorMessage from "components/errorMessage";
import SuccessMessage from "components/successMessage";

import useContracts from "hooks/useContracts";
import Yogies from "adapters/yogies";
import YogiesStaking from "adapters/yogiesStaking";

import { getErrorMessage } from "utils";
import { EXTERNAL_URLS } from "config";

export default function MintSection({ provider, merkleProof }) {
    const [contractYogies, contractYogiesStaking] = useContracts(provider);
    
    const yogiesObj = new Yogies(contractYogies);    

    const [hasMinted, setHasMinted] = useState();
    const [soldOut, setSoldOut] = useState();

    const [isTx, setIsTx] = useState();
    const [txHash, setTxHash] = useState();
    const [txError, setTxError] = useState();

    function isContractLoaded() {
        return provider && contractYogies && contractYogiesStaking;
      }
    
    useEffect(() => {
        if (isContractLoaded()) {
          provider.instance.on("block", (_) => {
            (async function () {
                let _numMinted = await yogiesObj.getNumberMinted(provider.address);
                let _remainingSupply = await yogiesObj.getRemainingSupply();

                setHasMinted(_numMinted.gt(0));
                setSoldOut(_remainingSupply.eq(0));
            })();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractYogies, contractYogiesStaking]);

    async function onMint(stake) {
        if (!isTx && isContractLoaded() && !hasMinted && !soldOut) {
            setIsTx();
            setTxHash();
            setTxError();
            
            const yogiesStakingObj = new YogiesStaking(contractYogiesStaking, provider.signer);

            try {
                setIsTx(true);
                let tx = await yogiesStakingObj.mintYogies(merkleProof, stake);
                setTxHash(tx.hash);
                await tx.wait();

                let _numMinted = await yogiesObj.getNumberMinted(provider.address);
                let _remainingSupply = await yogiesObj.getRemainingSupply();

                setHasMinted(_numMinted.gt(0));
                setSoldOut(_remainingSupply.eq(0));                
            } catch (e) {
                setTxError(getErrorMessage(e));
            }

            setIsTx();
            setTxHash();
        }
    }

    return (
        <>
            {
                soldOut &&
                <div className="box2_mint">
                    <ErrorMessage msg="Yogies Free Mint is sold out." />
                    <div className="button_mint" style={{backgroundColor: "#00a785", border: "2px solid #80ffc9"}} onClick={() => window.open(EXTERNAL_URLS.dashboard)}>DASHBOARD</div>
                    <Footer />
                </div>
            }
            
            {
                hasMinted && !soldOut &&
                <div className="box2_mint">
                    <SuccessMessage msg="Thank you for minting Yogies." />
                    <div className="button_mint" style={{backgroundColor: "#00a785", border: "2px solid #80ffc9"}} onClick={() => window.open(EXTERNAL_URLS.dashboard)}>DASHBOARD</div>
                    <Footer />
                </div>
                
            }
            

            {
                !hasMinted && !soldOut &&
                <div className="box2_mint">

                    {
                        !isTx &&
                        <div id="buttons">
                            <div className="button_mint" onClick={() => onMint(false)}>MINT</div>
                            <div className="button_mint" onClick={() => onMint(true)}>MINT AND STAKE</div>
                        </div>
                    }

                    {
                        isTx && !txError &&
                        <div id="tx">
                            {
                                txHash ?
                                    <div className="button_mint" onClick={() => window.open(EXTERNAL_URLS.etherscan + txHash, "_blank")}>View Transaction</div>
                                :
                                    <div style={{color: "white", textAlign: "center", fontSize: "12px", fontFamily: 'Russo One, sans-serif'}}>Please confirm the transaction in your wallet.</div>
                            }
                        </div>
                    }

                    {
                        txError &&
                        <ErrorMessage msg={txError} />
                    }
                    
                    <Footer />
                </div>  
            }
        </>
    )
}