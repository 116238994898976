import React, { useState } from "react"

import { getUnixTimeStamp } from "utils";
import useClock from "hooks/useClock";

export default function CountdownTimer({ to, show }) {
    const [now, setNow] = useState(getUnixTimeStamp());

    useClock(() => {
        setNow(_ => getUnixTimeStamp())
    })

    const difference = Math.max(to - now, 0);

    const sec = 1;
    const min = sec * 60;
    const hr = min * 60;
    const day = hr * 24;

    let textDay = Math.floor(difference / day);
    let textHour = Math.floor((difference % day) / hr);
    let textMin = Math.floor((difference % hr) / min);
    let textSec = Math.floor((difference % min) / sec);
    
    if (textDay.toString().length === 1) {
        textDay = "0" + textDay;
    }

    if (textHour.toString().length === 1) {
        textHour = "0" + textHour;
    }

    if (textMin.toString().length === 1) {
        textMin = "0" + textMin;
    }

    if (textSec.toString().length === 1) {
        textSec = "0" + textSec;
    }

    return (
        <>
            {
                show &&
                <>
                    <span style={{
                        color: "#ffffff", 
                        display: "flex", 
                        verticalAlign: "middle", 
                        justifyContent: "center", 
                        fontSize: "40px", 
                        fontFamily: 'Russo One, sans-serif',
                        marginTop: "56px"
                    }}>{textHour}:{textMin}:{textSec}</span>
                    <span style={{
                        color: "#efb615",
                        display: "flex",
                        verticalAlign: "middle",
                        justifyContent: "center",
                        fontSize: "10px",
                        fontFamily: 'Russo One, sans-serif',
                        marginTop: "-11px"
                    }}>UNTIL ROUND 2 STARTS</span>
                </>
            }
        </>
    )
}