import React, { memo, useEffect } from "react"
import Footer from "components/footer"

import ProviderHOC from "hoc/provider";
import useConnect from "hooks/useConnect";

const Connectsection = memo(
    ({ provider, setProvider }) => {
        const isConnected = !!provider?.address;
        const [, isConnecting, doConnect,, connection] = useConnect(provider);
    
        useEffect(() => {
          setProvider(connection);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connection]);
        
        return (
            <>
                {
                    !isConnected &&
                    <div className="box2_mint"> 
                        <div className="button_mint" onClick={doConnect}>CONNECT</div>
                        <Footer />
                    </div>  
                }
            </>
        )
    }
)

export default ProviderHOC(Connectsection);