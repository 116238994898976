import React, { useState, useEffect } from "react"
import Yogies from "adapters/yogies";
import useContracts from "hooks/useContracts";

import TimeLineListItem from "components/timeLineListItem";
import CountdownTimer from "components/countdownTimer";

import { MINT } from "config";
import { getUnixTimeStamp } from "utils";


export default function TimeLine({ provider }) {
    const isStarted = getUnixTimeStamp() >= MINT.MINT_START;

    const [contractYogies,] = useContracts(provider);
    const yogiesObj = new Yogies(contractYogies);

    const [soldOut, setSoldOut] = useState();    
    
    function isContractLoaded() {
        return provider && contractYogies;
      }
    
    useEffect(() => {
        if (isContractLoaded()) {
          provider.instance.on("block", (_) => {
            (async function () {
                let _remainingSupply = await yogiesObj.getRemainingSupply();
                setSoldOut(_remainingSupply.eq(0));
            })();
          });
          return () => provider.instance.off("block");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractYogies]);

    return (
        <>
            <div className="box_mint"> 
                <TimeLineListItem
                    title="Countdown has begun"
                    text="Once this becomes active our 24 hour countdown will begin."
                    active={MINT.COUNTDOWN_ACTIVE}
                    hasLine={true}                    
                />

                <TimeLineListItem
                    title="Guaranteed list + raffle winners"
                    text="This sale is limited to Guaranteed List + Free mint list raffle winners."
                    active={MINT.GUARANTEED_LIST_ACTIVE}
                    hasLine={true}
                    extraclass="circle-mob"
                />

                <TimeLineListItem
                    title="Free Mint list non-winners"
                    text="This sale is limited to Free mint list members that have not been selected in the raffle."
                    active={MINT.FREE_MINT_ACTIVE}
                    hasLine={true}
                    extraclass="circle-mob"
                />

                <TimeLineListItem
                    title="Sold out"
                    text="The collection is now sold out and only available on Opensea & Looksrare."
                    active={true}
                    hasLine={false}
                    extraclass="circle-mob"
                />

                <CountdownTimer 
                    to={MINT.ROUND_2_START}
                    show={false}
                />
            </div>
        </>
    )
}