import React, { memo, useEffect } from "react"

import { EXTERNAL_URLS } from "config"
import ProviderHOC from "hoc/provider"

import useConnect from "hooks/useConnect";

const Footer = memo(
    ({ provider, setProvider }) => {

        const [, isConnecting,, doDisconnect, connection] = useConnect(provider);

        useEffect(() => {
            setProvider(connection);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [connection]);

        return (
            <>
                <div className="row_mint">
                <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.discord, "_blank")}>
                        <img src="/images/dd.png"/>
                    </div>
                    <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.opensea, "_blank")}>
                        <img src="/images/os.png"/>
                    </div>  
                    <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.looksrare, "_blank")}>
                        <img src="/images/lr.png"/>
                    </div>
                </div>
                <span style={{
                        color: "#efb615",
                        display: "flex",
                        verticalAlign: "middle",
                        paddingTop: "10px",
                        justifyContent: "center",
                        fontSize: "10px",
                        fontFamily: 'Russo One, sans-serif'
                }}>
                    { provider?.address ? `${provider?.address.slice(0,10)}... connected wallet` : null}
                    { 
                        provider?.address && 
                        <span 
                            style={{color: "white", textDecoration: "underline", marginLeft: "5px", cursor: "pointer"}}
                            onClick={doDisconnect}
                        >disconnect</span>
                    } 
                </span>
            </>
        )
    }
)

export default ProviderHOC(Footer)

/**
 * <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.discord, "_blank")}>
                        <img src="/images/dd.png"/>
                    </div>
                    <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.opensea, "_blank")}>
                        <img src="/images/os.png"/>
                    </div>  
                    <div className="column_mint" onClick={() => window.open(EXTERNAL_URLS.looksrare, "_blank")}>
                        <img src="/images/lr.png"/>
                    </div>
 */