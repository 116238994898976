import React, { useState, useEffect } from "react";
import { collection, doc, getDocs } from "firebase/firestore";

import useFirebase from "hooks/useFirebase";
import useClock from "hooks/useClock";

export default function Statistics({ provider }) {
    //const [firestore,db] = useFirebase();

    const [availableSupply, setAvailableSupply] = useState(3333);
    const [remainingSupply, setRemainingSupply] = useState(0);

    //async function fetchFirestore() {
    //    let docs = []
    //    const walletsQuerySnapshot = await getDocs(collection(firestore, "mintData"));
    //    walletsQuerySnapshot.forEach((doc) => {
    //        docs.push({
    //        ...doc.data(),
    //        _id: doc.id,
    //        });
    //    });
    //    let _remainingSupply = docs && docs.length && docs[0] && docs[0].remainingSupply;
    //    setRemainingSupply(_remainingSupply);
    //}
    //
    //useEffect(() => {
    //    fetchFirestore();
    //}, [])
    //
    //useClock(() => {
    //    fetchFirestore();
    //}, 1000 * 60)
//
    return (
        <>
            <div className="box3_mint">
                {
                    remainingSupply !== undefined &&
                    <>
                        <div className="row_mint2">
                            <div className="column_mint2">{availableSupply}</div>
                            <div className="column_mint2">{remainingSupply}</div>
                        </div>
                        <div className="row_mint3">
                            <div className="column_mint2">AVAILABLE SUPPLY</div>
                            <div className="column_mint2">REMAINING SUPPLY</div>
                        </div>
                    </>
                }                
            </div>
        </>
    )
}