import React from "react"
import MintSection from "components/mintSection";
import ErrorMessage from "components/errorMessage";
import Footer from "components/footer";

import { MINT } from "config";
import { getUnixTimeStamp } from "utils";
import useProofs from "hooks/useProofs";

export default function ConnectedSection({ provider }) {
    const isConnected = !!provider?.address;
    const isStarted = getUnixTimeStamp() >= MINT.MINT_START;

    const [merkleProof] = useProofs(provider);
    const isWhiteListed = merkleProof !== undefined && merkleProof !== null;

    return (
        <>
            {
                isConnected &&
                <>
                    {
                        isStarted ?
                            <>
                                {
                                    isWhiteListed ?
                                        <MintSection 
                                            provider={provider}
                                            merkleProof={merkleProof}
                                        />
                                    :
                                        <div className="box2_mint">
                                            <ErrorMessage msg="You have not been selected this round. Please come back later." />
                                            <Footer />
                                        </div>
                                        
                                }
                            </>
                        :
                            <div className="box2_mint">
                                <ErrorMessage msg="Yogies Free Mint has not started yet." />
                                <Footer />
                            </div>                            
                    }                                                        
                </>
            }
        </>
    )
}