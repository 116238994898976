import { useState, useEffect } from 'react';

export default function useClock(dynamicUpdate, rate=1000) {
    const [_time, _setTime] = useState(0);

    useEffect(() => {  
        let interval = window.setInterval(() => {            
            _setTime(t => t + 1);
            dynamicUpdate();
        }, rate);

        return () => clearInterval(interval);
    })

    return [];
}