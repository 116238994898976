export const NETWORK = {
    TARGET_CHAIN_ID: 1,
    CHAIN_ID_TO_NAME: {
        "1": "mainnet",
        "3": "ropsten",
        "4": "rinkeby",
        "42": "kovan",
        "1337": "localhost"
    },
    INFURA_ID: "2ca7c361086740d98cafc1dff241c125",
    PORTIS_ID: "",
    FORTMATIC_ID: ""
}

export const CONTRACTS = {
    YOGIES: "Yogies",   
    YOGIES_STAKING: "YogiesStaking"
}

export const CLOUD = {
    bucket: "yogies-assets",
    proofs_file_path: "https://storage.googleapis.com/yogies-assets/proofs.json",
    contracts_file_path: "https://storage.googleapis.com/yogies-assets/hardhat_contracts.json"
}

export const MINT = {
    MINT_START: 0, //Sat May 07 2022 18:00:00 GMT+0200 (Central European Summer Time)
    ROUND_2_START: 1651939200 + 86400,
    COUNTDOWN_ACTIVE: true,
    GUARANTEED_LIST_ACTIVE: true,
    FREE_MINT_ACTIVE: true,
}

export const EXTERNAL_URLS = {
    etherscan: `https://etherscan.io/tx/`,
    opensea: "https://opensea.io/collection/yogies",
    looksrare: "https://looksrare.org/collections/0xCdD2c026B7c5eFA9222C9050A1D1C72C56768442",
    discord: "https://discord.com/invite/yogies",
    dashboard: "https://app.yogies.club"
}

export const ERRORS = {
    MESSAGES: [
        {
            error: "HAVE TO BUY AT LEAST 1",
            message: "Error: Your transaction has not gone through. You have to buy at least one ticket"
        },
        {
            error: "MetaMask Tx Signature: User denied transaction signature",
            message: "Error: You rejected the transaction."
        },
        {
            error: "insufficient funds for gas * price + value",
            message: "Error: You don't have enough Ether in your wallet to complete the transaction.",
            insert: true
        },
        {
            error: "INVALID PROOF",
            message: "Error: You are not on the white list. Please contact the team if you think this is incorrect."
        },
        {
            error: "User not whitelisted",
            message: "Error: You are not on the white list."
        },
        {
            error: "Freemint closed",
            message: "Minting is not opened yet"
        }
    ]
}