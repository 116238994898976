import React from "react"

export default function TimeLineListItem({ title, text, active, hasLine, extraclass }) {
    return (
        <>
            {
                active ? 
                <div className={"circle " + extraclass}> 
                    <div className="circle_active"> 
                    </div>
                    <div className="saletext">{title}</div>
                    <div className="saletext2">{text}
                        <span style={{color: "#77ffb1", marginRight: "-13px", padding: "0px 2px 0px 2px"}}>(ACTIVE)</span>
                    </div>
                </div>
                :
                <div className={"circle " + extraclass}> 
                    <div className="circle_inactive"> 
                    </div>
                    <div className="saletext">{title}</div>
                    <div className="saletext2">{text}
                        <span style={{color: "#f77", marginRight: "-13px", padding: "0px 2px 0px 2px"}}>(INACTIVE)</span>
                    </div>
                </div>
            }

            {
                hasLine &&
                <div className="line1">
                </div>
            }
        </>
    )
}