import React from 'react';
import Index from "pages/index.jsx"
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-98639753-3";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div>
        <Index />
    </div>
  );
}

export default App;
