export default class Yogies {
    constructor(instance) {
        this.instance = instance;
    }

    getAvailableSupply() {
        return this.instance.freeMintTotal();
    }

    getRemainingSupply() {
        return this.instance.freeMintsLeft();
    }

    getNumberMinted(address) {
        return this.instance.numberMinted(address);
    }
}