import { useState, useEffect } from "react";
import axios from "axios";
import { CLOUD } from "config";
import Proofs from "proofs/proofs.json"

export default function useProofs(provider) {
    const [merkleProof, setMerkleProof] = useState();
    const [proofs, setProofs] = useState();

    async function fetchProofs() {
        setProofs(Proofs);  
    }

    useEffect(() => {
        if (provider && proofs) {
            let _proofs = proofs && proofs.proofs && proofs.proofs[provider.address.toLowerCase()];
            setMerkleProof(_proofs);
        }
    }, [provider, proofs])

    useEffect(() => {
        fetchProofs();
    }, [])


    return [merkleProof];
}
