import React, { useEffect} from "react"

import ProviderHOC from "hoc/provider";

import FloatingObjects from "components/floatingObjects"
import Statistics from "components/statistics"
import TimeLine from "components/timeLine"
import ConnectedSection from "components/connectedSection"
import ConnectSection from "components/connectSection";

import ReactGA from 'react-ga';

function Home({ provider }) {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    return (
        <div>
            <FloatingObjects />
            <Statistics
                provider={provider}
            />            
            <TimeLine />

            <ConnectSection />
            <ConnectedSection 
                 provider={provider}
            />            
        </div>
    )
}

export default ProviderHOC(Home)